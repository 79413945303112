export default {
  // Header
  headerTitle: `Rejoins l'univers Vibes!`,
  headerDescription: `Organiser et participer à des événements spontanés n'a jamais été aussi simple`,

  headerComing: " ",

  // Intro
  introDescription: `Découvrez la communauté Vibes !`,
  scrollDown: "Faites glisser vers le bas",
  soon: "Soon available",

  // Body
  bodyTitle: "Découvrez la communauté Vibes !",
  bodyDescription: `Vibes te simplifie la vie et t'offre de nouvelles opportunités :`,
  bodyDescription2: `Profite avec tes amis ou découvre de nouvelles personnes en invitant les gens autour de toi à te rejoindre !\n
	Crée des événements spontanés ou choisi des activités pour les jours à venir`,
  bodyFooterDescription: " ",

  // Footer

  footerDescription:
    "Télécharge l'application Vibes sur l'App Store pour iOS ou Google Play pour Android",
  footerDescriptionIOS: "Télécharge l'application Vibes sur l'App Store",
  footerDescriptionAndroid: "Téléchargez l’application Vibes sur Google Play",
  footerPrivacy:
    "L'application Vibes est destinée à des populations étudiantes, merci de nous contacter à l'adresse mail suivante pour en savoir plus: contact@vibesapp.eu",

  termsStart: "Vibes ",
  termsPrivacy: "Politique de protection de la vie privé",
  termsBetween: " et ",
  termsUse: "Conditions générales d'accès et d'utilisation",
  termsEnd: ".",

  // Privacy Page
  loading: "Chargement...",
};
