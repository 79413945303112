import React from "react";
import localize from "../../../../redux/translation/localize";

class MainPageBody extends React.Component {
  renderBottomBody = () => {
    const { t } = this.props;
    const { outerWidth } = window;

    if (outerWidth > 500) {
      return (
        <div className="bottomBody" id="#bottombody">
          <div className="mediumCase">
            <div className="box">
              <div className="textBox">
                <div className="title">{t("bodyTitle")}</div>
                <div className="description">{t("bodyDescription")}</div>
                <div className="description">{t("bodyDescription2")}</div>
              </div>
              <div className="bodyImage">
                <div className="image" />
              </div>
            </div>
          </div>
          <div className="bodyBottom">
            {/* <div className="description">{t('bodyFooterDescription')}</div> */}
          </div>
        </div>
      );
    }
    return (
      <div className="bottomBody">
        <div className="mediumCase">
          <div className="bodyImage">
            <div className="image" />
          </div>
          <div className="textBox">
            <div className="title">{t("bodyTitle")}</div>
            <div className="description">{t("bodyDescription")}</div>
            <div className="description">{t("bodyDescription2")}</div>
          </div>
        </div>
        <div className="bodyBottom">
          {/* <div className="description">{t('bodyFooterDescription')}</div> */}
        </div>
      </div>
    );
  };

  handleScrollDown = () => {
    const doc = document.getElementById("#bottombody");

    if (doc) {
      window.scrollTo(0, doc.offsetTop);
    }
  };

  renderScrollDown = () => {
    const { t } = this.props;

    return (
      <div className="scrollDown" onClick={this.handleScrollDown}>
        <div className="text">{t("scrollDown")}</div>
        <div className="icon" />
      </div>
    );
  };

  renderUpperBody = () => {
    return null;
    const { t } = this.props;

    return (
      <div className="upperBody">
        <div className="container">
          <div className="avatar" />
          <div className="bodyText">
            <div className="logo" />
            <div className="description">{t("introDescription")}</div>
          </div>
        </div>
        {this.renderScrollDown()}
      </div>
    );
  };

  render() {
    return (
      <div className="mainPageBody">
        {this.renderUpperBody()}
        {this.renderBottomBody()}
      </div>
    );
  }
}

export default localize()(MainPageBody);
