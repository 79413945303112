import React from "react";
import localize from "../../redux/translation/localize";

class Dropdown extends React.Component {
  state = {
    status: "closed",
  };

  handlePress = () => {
    this.setState({
      status: this.state.status === "closed" ? "open" : "closed",
    });
  };

  render() {
    const { selected, options, containerStyle, device, handlePress } =
      this.props;
    console.log(selected);
    return (
      <div className={`dropdown ${containerStyle}`}>
        <div
          className={`dpItem selected state ${this.state.status}`}
          onClick={this.handlePress}
        >
          <div className="text">{device ? selected.short : selected.long}</div>
          <div className="iconDropdown" />
        </div>
        <div className={`dropdownOptions ${this.state.status}`}>
          {options.map((item, index) => (
            <div
              key={index}
              className={`dpItem ${
                item.value === selected.value ? "selected" : ""
              }`}
              onClick={() => {
                this.handlePress();
                handlePress(item.value);
              }}
            >
              {device ? item.short : item.long}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default localize()(Dropdown);
