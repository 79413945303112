export default {
  // Header
  headerTitle: `L’application pour rencontrer des étudiants\u00A0!`,
  headerDescription: `Rencontre d’autres étudiants dans ta vibe autour d’un verre, en faisant un foot... ou ce que tu aimes faire !`,

  headerComing: " ",

  // Intro
  introDescription: `Rejoins la communauté VIBES`,
  scrollDown: "Faites glisser vers le bas",
  soon: "Bientôt disponible",

  // Body
  bodyTitle: "Rejoins la communauté VIBES",
  bodyDescription: `Une communauté étudiante active autour de toi.`,
  bodyDescription2: `Vibes est une application qui permet aux étudiants de proposer ou de participer à des activités et de faire de nouvelles rencontres. Que ce soit pour faire du sport, la fête, une activité culturelle et bien plus encore.`,
  bodyFooterDescription: " ",

  // Footer

  footerDescription:
    "Télécharge l'application Vibes sur l'App Store pour iOS ou Google Play pour Android",
  footerDescriptionIOS: "Télécharge l'application Vibes sur l'App Store",
  footerDescriptionAndroid: "Téléchargez l’application Vibes sur Google Play",
  footerPrivacy:
    "L'application Vibes est destinée à des étudiants, merci de nous contacter à l'adresse mail suivante pour en savoir plus: contact@vibesapp.eu",

  termsStart: "Vibes ",
  termsPrivacy: "Politique de protection de la vie privé",
  termsBetween: " et ",
  termsUse: "Conditions générales d'accès et d'utilisation",
  termsEnd: ".",

  // Privacy Page
  loading: "Chargement...",
};
