import "./Legal.scss";
export default function Legal() {
  return (
    <div className="legal">
      <h2>1. Accord d'acceptation Conditions d'utilisation.</h2>
      <p>
        En créant un compte VIBES, que ce soit sur un appareil mobile, une
        application mobile (ensemble, le « Service »), vous acceptez d'être lié
        par (i) les présentes Conditions d'utilisation, (ii)notre Politique de
        confidentialité, chacun incorporé par référence au présent Accord, et
        (iii) toutes conditions divulguées et que vous acceptez si vous achetez
        des fonctions, produits ou services supplémentaires que nous offrons sur
        le Service (ensemble, le présent « Accord »). Si vous n'acceptez pas
        l'ensemble des conditions du présent Accord et que vous refusez d'être
        lié par celles-ci, veuillez ne pas utiliser le Service.
      </p>
      <p>
        Nous sommes susceptibles de modifier le présent Accord et le Service à
        tout moment. Nous pouvons le faire pour différentes raisons, notamment
        pour refléter des modifications au niveau de la loi ou de ses exigences,
        de nouvelles fonctions ou des changements dans les pratiques
        commerciales. La version la plus récente du présent Accord sera publié
        dans la section Paramètres, et nous vous suggérons d'en consulter
        régulièrement la version la plus récente. La version la plus récente est
        celle qui s'applique. Si les modifications comprennent des modifications
        matérielles qui affectent vos droits ou obligations, nous vous
        notifierons au moins 30 jours en avance desdites modifications (à moins
        que nous soyons dans l'incapacité de le faire conformément aux lois en
        vigueur) par des moyens raisonnables, qui pourraient inclure des
        notifications au travers du Service ou par e-mail. L'utilisation du
        Service après l'entrée en vigueur des modifications à valeur
        d'acceptation de l'Accord révisé.
      </p>
      <h2>2. Admissibilité.</h2>
      <p>
        Vous n'êtes pas autorisé à créer un compte, ni à accéder ou utiliser le
        Service, ou les systèmes sur lequel il réside, si toutes les
        informations suivantes ne sont pas vraies :
      </p>
      <ul>
        <li>• vous êtes âgés au moins de 18 ans.</li>
        <li>
          {" "}
          • vous pouvez conclure un contrat créant des obligations avec Vibes,
        </li>
        <li>
          • vous respecterez le présent Accord et l'ensemble des lois, règles et
          règlements locaux, étatiques, nationaux et internationaux applicables,
          et
        </li>
        <li>
          • vous n'avez jamais été condamné pour un crime ou un délit passible
          de poursuites (ou un crime de gravité similaire), un crime à caractère
          sexuel ou impliquant de la violence, et n'êtes pas tenu de vous
          déclarer en tant que délinquant sexuel auprès de tout registre des
          délinquants sexuels au niveau étatique, fédéral ou local.
        </li>
      </ul>
      <h2>3. Votre Compte.</h2>
      <p>
        Vous êtes responsable de maintenir la confidentialité des identifiants
        de connexion que vous utilisez pour vous inscrire sur Vibes, et vous
        êtes seul responsable de toutes les activités survenant sous ces
        identifiants. Si vous pensez que quelqu'un a eu accès à votre compte,
        veuillez nous contacter immédiatement.
      </p>
      <h2>4. Modification du Service et Résiliation.</h2>
      <p>
        Vibes s'efforce d'améliorer en permanence le Service et de vous apporter
        des fonctionnalités supplémentaires que vous trouverez intéressantes et
        utiles. Cela signifie que nous pouvons ajouter de nouvelles fonctions de
        produit ou des améliorations à tout moment, et que nous pouvons
        également supprimer certaines fonctions. Dans le cas où lesdites actions
        n'affectent pas matériellement vos droits ou obligations, il se peut que
        nous ne vous avertissions pas avant de les appliquer. Nous pouvons même
        suspendre entièrement le Service, auquel cas nous vous avertirons par
        avance sauf si des circonstances atténuantes, tels que des problèmes de
        sûreté ou de sécurité, nous empêchent de le faire.
      </p>
      <p>
        Vous pouvez résilier votre compte à tout moment, pour quelque raison que
        ce soit, en suivant les instructions dans les « Paramètres » du Service.
        <strong>
          Toutefois, si vous utilisez un compte de paiement tiers tel que l'App
          Store d'Apple ou l'iTunes Store, selon le cas (« App Store ») ou le
          Google Play Store, vous devrez gérer les achats intégrés via ce compte
          pour éviter une facturation supplémentaire.
        </strong>{" "}
        Vibes peut clôturer votre compte à tout moment et sans préavis si nous
        pensons que vous avez violé le présent Accord. Après une telle
        résiliation, vous n’aurez droit à aucun remboursement de vos achats.
      </p>
      <p>
        Après résiliation de votre compte, le présent Accord sera résilié, à
        l’exception des stipulations suivantes qui continueront de s’appliquer à
        vous et à Vibes : sections 4, 5 et 12 à 19.{" "}
      </p>
      <h2>5. Sûreté ; vos interactions avec d'autres Membres.</h2>
      <p>
        Bien que Vibes s'efforce d'encourager une expérience respectueuse du
        membre au travers de fonctions telles que la double option qui permet
        aux membres de communiquer uniquement si tous deux ont indiqué leur
        intérêt pour l'autre, Vibes n'est pas responsable de la conduite de tout
        membre dans le cadre ou en dehors du Service. Vous acceptez d'être
        prudent dans toutes vos interactions avec d'autres membres, en
        particulier si vous décidez d'échanger en dehors du Service ou de vous
        rencontrer en personne. De plus, vous acceptez de consulter et de suivre
        les conseils de Vibes avant d'utiliser le Service. Vous acceptez de ne
        pas fournir vos informations financières (par exemple, votre numéro de
        carte bancaire ou de compte bancaire), ni de transférer de l’argent par
        virement ou autre à d'autres membres.
      </p>
      <p>
        VOUS ÊTES SEUL RESPONSABLE DE VOS INTERACTIONS AVEC D'AUTRES MEMBRES.
        VOUS COMPRENEZ QUE Vibes NE PROCÈDE À AUCUNE VÉRIFICATION D'ANTÉCÉDENTS
        CRIMINELS SUR SES MEMBRES NI N'ENQUÊTE AUTREMENT SUR LES ANTÉCÉDENTS DE
        SES MEMBRES. Vibes NE FAIT AUCUNE DÉCLARATION NI GARANTIE QUANT À LA
        CONDUITE OU LA COMPATIBILITÉ DES MEMBRES.
      </p>
      <h2>6. Les droits que Vibes vous accorde.</h2>
      <p>
        Vibes vous accorde une licence personnelle, mondiale, libre de
        redevance, incessible, non-exclusive, révocable et ne pouvant faire
        l'objet d'une souslicence afin d'accéder au Service et de l'utiliser.
        Cette licence a uniquement pour objet de vous permettre d'utiliser et de
        bénéficier des avantages des Services conçus par Vibes et autorisés par
        le présent Accord. Cette licence et toute autorisation d'accéder au
        Service seront automatiquement révoquées si vous effectuez l'une des
        choses suivantes :
      </p>
      <ul>
        <li>
          • d'utiliser le Service ou tout contenu du Service à toutes fins
          commerciales sans notre consentement écrit.
        </li>
        <li>
          • de copier, modifier, transmettre, créer toute œuvre dérivée,
          utiliser ou reproduire de quelque façon que ce soit tout support
          assujetti à un droit d'auteur, toute image, marque commerciale,
          dénomination commerciale, marque de service ou tout autre élément de
          propriété intellectuelle, contenu ou information exclusive accessibles
          au travers du Service sans le consentement écrit préalable de Vibes.
        </li>
        <li>
          • d'exprimer ou de sous-entendre que l'une de vos déclarations est
          approuvée par Vibes.
        </li>
        <li>
          • d'utiliser tout robot, agent numérique, araignée, collecteur,
          racleur, application de recherche/extraction de site, passerelle de
          procuration ou autre appareil manuel ou automatique, méthode ou
          processus visant à accéder, extraire, indexer, fouiller les données ou
          reproduire ou éluder de quelque façon que ce soit la structure de
          navigation ou la présentation du Service ou de ses contenus.
        </li>
        <li>
          • d'utiliser le Service d'une manière qui pourrait interférer avec
          ledit Service, les serveurs ou les réseaux connectés audit Service,
          les interrompre ou leur nuire.
        </li>
        <li>
          • de télécharger des virus ou autres codes malveillants ou de
          compromettre autrement la sécurité du Service.
        </li>
        <li>
          • de falsifier tout en-tête ou manipuler autrement les identifiants
          afin de masquer l'origine de toute information transmise sur ou à
          travers le Service.
        </li>
        <li>
          • de copier ou de reproduire toute partie du Service sans
          l'autorisation préalable écrite de Vibes.
        </li>
        <li>
          • d'utiliser des métabalises, un code ou d'autres appareils contenant
          toute référence à Vibes ou au Service (ou toute marque, dénomination
          commerciale, marque de service, logo ou slogan de Vibes) pour diriger
          toute personne vers un autre site Web à quelque fin que ce soit.
        </li>
        <li>
          • de modifier, d'adapter, d'octroyer une sous-licence, de traduire, de
          vendre, de procéder à une ingénierie inverse, de décrypter, de
          décompiler ou de désassembler autrement toute portion du Service, ou
          d'obtenir de tiers qu'ils le fassent.
        </li>
        <li>
          • d'utiliser ou de développer toutes applications qui interagissent
          avec le Service ou le Contenu ou les informations d'autres membres
          sans notre consentement écrit.
        </li>
        <li>
          • d'utiliser, d'accéder à ou de publier l'interface de programmation
          de l'application Vibes sans notre consentement écrit. • de sonder,
          d'analyser ou de tester la vulnérabilité de notre Service ou de tout
          système ou réseau.
        </li>
        <li>
          • d'encourager ou de promouvoir toute activité qui viole le présent
          Accord.
        </li>
      </ul>
      <p>
        Vibes peut enquêter et entreprendre toute action en justice disponible
        en réponse aux utilisations illégales et/ou non autorisées du Service, y
        compris la résiliation de votre compte.
      </p>
      <p>
        Tout logiciel que nous vous fournissons peut automatiquement télécharger
        et installer des améliorations, mises à jour ou autres nouvelles
        fonctions. Vous pouvez ajuster lesdits téléchargements automatiques dans
        les paramètres de votre appareil.
      </p>
      <h2>7. Les droits que vous accordez à Vibes.</h2>
      <p>
        En créant un compte, vous accordez à Vibes une licence mondiale,
        transférable, pouvant donner lieu à des sous-licences et libre de
        redevance, et le droit d'héberger, de stocker, d'utiliser, de copier,
        d'afficher, de reproduire, d'adapter, de modifier, de publier et de
        distribuer toutes les informations auxquelles vous nous autorisez à
        accéder depuis des tierces parties comme Facebook, ainsi que les
        informations que vous publiez, chargez, affichez ou rendez disponibles
        (ensemble, « Publication ») sur le Service ou que vous transmettez à
        d'autres membre (ensemble, « Contenu »). La licence de Vibes concernant
        votre Contenu n'est pas exclusive, à l'exception des œuvres dérivées
        créées via l'utilisation du Service, pour lesquelles la licence de Vibes
        sera exclusive. Par exemple, dans le cas de captures d'écran du Service,
        comportant votre Contenu, la licence de Vibes sera exclusive. De plus,
        pour que Vibes puisse empêcher l'utilisation de votre Contenu en dehors
        du Service, vous autorisez Vibes à agir en votre nom dans le cas d'une
        utilisation non autorisée de votre Contenu, extrait du Service par
        d'autres membres ou des tierces parties. Cela comprend expressément le
        pouvoir, et non l'obligation, d'envoyer des avis conformément à
        l'article 17 U.S.C. § 512(c)(3) (c'est-à-dire les avis de retrait du
        DMCA) en votre nom si votre Contenu est extrait du Service et utilisé
        par des tierces parties en dehors de celui-ci. Notre licence sur votre
        Contenu est régie par vos droits conformément aux lois en vigueur (par
        exemple les lois sur la protection des données personnelles dans la
        mesure où le Contenu contient des informations personnelles telles que
        définies par ces lois) et n'existe que dans le but limité de développer,
        de fournir, de gérer et d'améliorer le Service, ainsi que pour la
        recherche et le développement d'autres services. Vous acceptez que tout
        Contenu que vous placez ou que vous nous autorisez à placer sur le
        Service peut être vu par d'autres membres ainsi que par toute personne
        qui consulte ou qui participe au Service (comme des personnes qui
        peuvent recevoir du Contenu partagé par d'autres membres de Vibes).
      </p>
      <p>
        Vous convenez que toutes les informations que vous soumettez lors de la
        création de votre compte, y compris des informations soumises à partir
        de votre compte Facebook, sont exactes et véridiques, et que vous avez
        le droit de publier le Contenu sur le Service et d’accorder à Vibes la
        licence susmentionnée.
      </p>
      <p>
        Vous comprenez et acceptez que nous puissions surveiller ou examiner
        tout Contenu que vous publiez dans le cadre d'un Service. Nous pouvons
        supprimer tout Contenu, en tout ou en partie, si, selon notre seul
        jugement, il viole le présent Accord ou est susceptible de nuire à la
        réputation du Service.
      </p>
      <p>
        Lors de toute communication avec nos représentants du service client,
        vous acceptez d'être respectueux et aimable. Si nous considérons que
        votre comportement envers l'un de nos représentants du service client ou
        autres employés est à tout moment menaçant, harcelant ou injurieux, nous
        nous réservons le droit de clôturer immédiatement votre compte.
      </p>
      <p>
        En contrepartie de l'autorisation que Vibes vous accorde d'utiliser le
        Service, vous acceptez que nous, nos affiliés et nos partenaires tiers
        puissent placer des annonces sur le Service. En soumettant des
        suggestions ou des commentaires à Vibes à propos de nos Services, vous
        acceptez que Vibes puisse utiliser et partager ce retour, à toute fin
        que ce soit, sans vous offrir de compensation.
      </p>
      <p>
        Veuillez noter que Vibes peut accéder, stocker et divulguer les
        informations et le Contenu de votre compte si nous sommes tenus de le
        faire en vertu de la loi, dans le cadre du présent Accord, ou si nous
        croyons de bonne foi que lesdits accès, stockage ou divulgation
        constituent un intérêt légitime, afin de : (i) respecter une procédure
        judiciaire ; (ii) faire appliquer le présent Accord ; (iii) répondre à
        toutes réclamations selon lesquelles tout Contenu violerait les droits
        de tiers ; (iv) répondre à vos demandes au service client ; ou (v)
        protéger les droits, la propriété ou la sûreté personnelle de la Société
        ou de toute autre personne.
      </p>
      <h2>8. Règles de la communauté.</h2>
      <p>En utilisant le Service, vous acceptez de vous abstenir : </p>
      <ul>
        <li>
          • d'utiliser le Service à toute fin illégale ou interdite par le
          présent Accord.
        </li>
        <li>
          • d'utiliser le Service à des fins malveillantes ou malfaisantes •
          d'utiliser le Service afin de porter atteinte à Vibes
        </li>
        <li>
          • d'envoyer du courrier indésirable, de demander de l'argent aux
          membres ou de les escroquer.
        </li>
        <li>
          • de vous faire passer pour toute une autre personne ou entité ou de
          publier toutes images d'une autre personne sans sa permission.
        </li>
        <li>
          • de persécuter, de traquer, d'intimider, d'attaquer, de harceler, de
          maltraiter ou de diffamer toute personne.
        </li>
        <li>
          • de publier tout Contenu qui viole ou enfreint les droits de
          quiconque, y compris les droits de publicité, au respect de la vie
          privée, d'auteur, de marque commerciale ou tout autre droit de
          propriété intellectuelle ou contractuel.
        </li>
        <li>
          • de publier tout Contenu constituant un discours haineux, menaçant,
          sexuellement explicite ou pornographique.
        </li>
        <li>
          • de publier tout Contenu incitant à la violence ou contenant de la
          nudité, de la violence graphique ou gratuite.
        </li>
        <li>
          • de publier du contenu qui comprend des propos racistes, intolérants,
          haineux ou qui encourage les violences physiques contre un groupe ou
          une personne en particulier.
        </li>
        <li>
          • de demander des mots de passe à quelque fin que ce soit, ou des
          informations permettant une identification personnelle à des fins
          commerciales ou illégales auprès d'autres membres ou de diffuser les
          informations personnelles d'une autre personne sans sa permission.
        </li>
        <li>
          • d'utiliser le compte d'un autre membre, partager un compte avec un
          autre membre ou posséder plus d'un compte. • de créer un autre compte
          si nous avons déjà résilié votre compte, à moins que vous n'ayez notre
          permission.
        </li>
      </ul>
      <p>
        Vibes se réserve le droit d'enquêter et/ou de clôturer votre compte sans
        rembourser vos achats, si vous avez enfreint le présent Accord, fait une
        mauvaise utilisation du Service ou si vous avez eu un comportement que
        Vibes considère inapproprié ou illégal, y compris en cas d’actions ou de
        communications survenant en dehors du Service.
      </p>
      <h2>9. Contenu des autres membres.</h2>
      <p>
        Bien que Vibes se réserve le droit d'examiner et de supprimer tout
        Contenu qui viole le présent Accord, ledit Contenu relève de la seule
        responsabilité du membre qui le publie, et Vibes ne peut garantir que
        l'ensemble du Contenu sera conforme au présent Accord. Si vous voyez un
        Contenu sur le Service qui viole le présent Accord, veuillez le signaler
      </p>
      <h2>10. Avis de non-responsabilité.</h2>
      <p>
        Vibes FOURNIT LE SERVICE « TEL QUEL » ET « SELON LA DISPONIBILITÉ » ET,
        DANS LA MESURE AUTORISÉE PAR LA LOI APPLICABLE, N'ACCORDE AUCUNE
        GARANTIE DE QUELQUE NATURE QUE CE SOIT, QU'ELLE SOIT EXPRESSE,
        IMPLICITE, OBLIGATOIRE OU AUTRE CONCERNANT LE SERVICE (Y COMPRIS TOUT LE
        CONTENU DE CELUI-CI), INCLUANT SANS S'Y LIMITER TOUTE GARANTIE IMPLICITE
        DE QUALITÉ SATISFAISANTE, QUALITÉ MARCHANDE, ADAPTATION À UNE FIN
        PARTICULIÈRE OU ABSENCE DE CONTREFAÇON. Vibes NE DÉCLARE NI NE GARANTIT
        QUE (A) LE SERVICE SERA SANS INTERRUPTION, SÛR OU LIBRE D'ERREUR, (B)
        TOUS LES DÉFAUTS OU ERREURS DU SERVICE SERONT CORRIGÉS, OU (C) QUE TOUT
        CONTENU OU TOUTE INFORMATION QUE VOUS OBTENEZ SUR OU À TRAVERS LES
        SERVICES SERA EXACT. Vibes N'ASSUME AUCUNE RESPONSABILITÉ POUR TOUT
        CONTENU QUE VOUS, UN AUTRE MEMBRE OU UN TIERS PUBLIE, ENVOIE OU REÇOIT
        VIA LE SERVICE. L'ACCÈS À TOUT MATÉRIEL TÉLÉCHARGÉ OU OBTENU À TRAVERS
        L'UTILISATION DU SERVICE EST À VOTRE ENTIÈRE DISCRÉTION ET À VOS PROPRES
        RISQUES.
      </p>
      <h2>11. Services de tiers.</h2>
      <p>
        Le Service peut contenir des annonces et des promotions proposées par
        des tiers ainsi que des liens vers d'autres sites ou ressources
        Internet. Vibes ne sera pas tenu responsable de la disponibilité (ou
        indisponibilité) desdits sites Web ou ressources externes. Si vous
        choisissez d'interagir avec les tiers mis à disposition par le biais de
        notre Service, leur relation avec vous sera régie par les conditions
        desdits tiers. Vibes ne sera en aucun cas tenu responsable des
        conditions ou actions desdits tiers.
      </p>
      <h2>12. Limitation de responsabilité.</h2>
      <p>
        DANS LA PLUS GRANDE MESURE PERMISE PAR LA LOI APPLICABLE, EN AUCUN CAS
        Vibes, SES AFFILIÉS, EMPLOYÉS, CONCÉDANTS OU PRESTATAIRES DE SERVICE NE
        SERONT TENUS RESPONSABLES DE TOUS DOMMAGES INDIRECTS, CONSÉCUTIFS,
        EXEMPLAIRES, ACCESSOIRES, SPÉCIAUX, PUNITIFS OU ACCRUS, Y COMPRIS,
        NOTAMMENT, UNE PERTE DE PROFITS, QU'ELLE SOIT SURVENUE DIRECTEMENT OU
        INDIRECTEMENT, OU TOUTE PERTE DE DONNÉES, D'USAGE, DE CLIENTÈLE OU
        AUTRES PERTES IMMATÉRIELLES, NAISSANT DE : (I) VOTRE ACCÈS, UTILISATION
        OU INCAPACITÉ D'ACCÉDER OU D'UTILISER LE SERVICE, (II) LA CONDUITE OU LE
        CONTENU D'AUTRES MEMBRES OU TIERS SUR LES SERVICES, À TRAVERS CEUX-CI OU
        À LA SUITE DE LEUR UTILISATION ; OU (III) L'ACCÈS, L'UTILISATION OU
        L'ALTÉRATION NON AUTORISÉS DE VOTRE CONTENU, MÊME SI Vibes A ÉTÉ INFORMÉ
        DE LA POSSIBILITÉ DESDITS DOMMAGES. EN AUCUN CAS, Vibes NE SERA TENU
        RESPONSABLE DE L'ENSEMBLE DES PLAINTES RELATIVES AU SERVICE POUR UN
        MONTANT SUPÉRIEUR AU MONTANT QUE VOUS AVEZ PAYÉ, LE CAS ÉCHÉANT, À Vibes
        POUR LE SERVICE, ET DE 100 USD PENDANT LA DURÉE DE POSSESSION DE VOTRE
        COMPTE.
      </p>
      <p>
        CERTAINES JURIDICTIONS NE PERMETTENT PAS L'EXCLUSION OU LA LIMITATION DE
        CERTAINS DOMMAGES-INTÉRÊTS, PAR CONSÉQUENT CERTAINES OU L'ENSEMBLE DES
        EXCLUSIONS ET LIMITATIONS DE LA PRÉSENTE SECTION PEUVENT NE PAS VOUS
        ÊTRE APPLICABLES.
      </p>
    </div>
  );
}
