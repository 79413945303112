import React from "react";
import localize from "../../../../redux/translation/localize";
import urlConf from "../../../../Routes/urlConf";

class MainPageFooter extends React.PureComponent {
  goToLink = (device) => {
    window.open(urlConf[device]);
  };

  renderStores = () => {
    const { device } = this.props;
    // const comingSoon = t('headerComing');

    // if (comingSoon !== 'headerComing') {
    //   return (
    //     <div className={`onlineStores ${device}`}/>
    //   )
    // }

    if (!device) {
      return (
        <div className="onlineStores">
          <div
            className="androidStore"
            onClick={() => this.goToLink("android")}
          ></div>
          <div
            className="appleStore"
            onClick={() => this.goToLink("apple")}
          ></div>
        </div>
      );
    }
    return (
      <div className={`onlineStores ${device}`}>
        <div
          className={`${device}Store`}
          onClick={() => this.goToLink(device)}
        ></div>
      </div>
    );
  };

  getDescription = () => {
    const { t, device } = this.props;

    if (device) {
      return t(
        device === "apple"
          ? "footerDescriptionIOS"
          : "footerDescriptionAndroid",
      );
    }
    return t("footerDescription");
  };

  render() {
    const { t, lang } = this.props;

    return (
      <div className="mainPageFooter">
        <div className="imageFooter">
          <div className="footerBox">
            <div className="description">{this.getDescription()}</div>
            {this.renderStores()}
          </div>
        </div>
        <div className="footerPrivacy">
          <div className="description">{t("footerPrivacy")}</div>
          <div className="termsBox">
            {t("termsStart")}
            <a href={`/privacy`} className="termsLink">
              {t("termsPrivacy")}
            </a>
            {t("termsBetween")}
            <a href={`/terms_of_use`} className="termsLink">
              {t("termsUse")}
            </a>
            {t("termsEnd")}
          </div>
        </div>
      </div>
    );
  }
}

export default localize()(MainPageFooter);
