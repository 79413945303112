import React from "react";
import localize from "../../../../redux/translation/localize";
import urlConf from "../../../../Routes/urlConf";

class MainPageHeader extends React.PureComponent {
  handleScrollDown = () => {
    const doc = document.getElementById("mainPageHeader");

    if (doc) {
      window.scrollTo(0, doc.offsetHeight + doc.offsetTop);
    }
  };

  goToLink = (device) => {
    window.open(urlConf[device]);
  };

  renderStores = () => {
    const { device, t } = this.props;
    // const comingSoon = t('headerComing');

    // if (comingSoon !== 'headerComing') {
    //   return (
    //     <div className={`onlineStores ${device}`}/>
    //   )
    // }

    if (!device) {
      return (
        <div className="onlineStores">
          <div
            className="androidStore"
            onClick={() => this.goToLink("android")}
          ></div>
          <div
            className="appleStore"
            onClick={() => this.goToLink("apple")}
          ></div>
        </div>
      );
    }
    return (
      <div className={`onlineStores ${device}`}>
        <div
          className={`${device}Store`}
          onClick={() => this.goToLink(device)}
        ></div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const { device } = this.props;

    return (
      <div className="mainPageHeader" id="mainPageHeader">
        <div className={`logoContainer ${device}`}>
          <div className="logo" />
        </div>
        <div className="messageContainer">
          <div className="title">{t("headerTitle")}</div>
          <div className="description">{t("headerDescription")}</div>
        </div>
        {this.renderStores()}
        {/* <div className="soon">{t("soon")}</div> */}
        <div className={`scrollDown ${device}`} onClick={this.handleScrollDown}>
          <div className="text">{t("scrollDown")}</div>
          <div className="icon" />
        </div>
      </div>
    );
  }
}

export default localize()(MainPageHeader);
