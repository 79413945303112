import Legal from "../pages/Legal/Legal";
import MainPage from "../pages/MainPage/MainPage";
import Privacy from "../pages/Privacy/Privacy";

const routes = {
  HOME: "/",
  PRIVACY: "/privacy",
  TERMS_OF_USE: "/terms_of_use",
};

const config = [
  {
    path: routes.HOME,
    exact: true,
    component: MainPage,
  },
  {
    path: routes.PRIVACY,
    exact: true,
    component: Privacy,
  },
  {
    path: routes.TERMS_OF_USE,
    exact: true,
    component: Legal,
  },
];

export default routes;
export { config };
